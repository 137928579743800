import * as React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Pagination } from '@material-ui/lab';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import Seo from '../components/Seo';
import { LocationLanguageContext } from '../contexts/LocationLanguageContext';
import { LocationLanguageContextType } from '../typings';
import { Languages } from '../utils/getAllSupportedLanguages';
import { useStrapiDashboards } from '../hooks/useStrapiDashboards';
import DashboardCard from '../components/Dashboard/DashboardCard';
import useDashboardsLayoutStyles from '../styles/DashboardLayout';

const DashboardsLayout: React.FC = () => {
  const classes = useDashboardsLayoutStyles();
  const locationLanguage = React.useContext<LocationLanguageContextType | null>(LocationLanguageContext);
  const language: string = locationLanguage?.language ?? Languages.English;
  const { strapiDashboardPage, dashboards, strapiSearchResult } = useStrapiDashboards();
  const dashboardsPageContent = strapiDashboardPage.find((x) => x.locale === language);
  const searchResultContent = strapiSearchResult.find((x: any) => x.locale === language);

  const MAX_RESULT_PER_PAGE = 20;
  const NUM_PAGE = Math.ceil(dashboards.length / MAX_RESULT_PER_PAGE);
  const [page, setPage] = React.useState(1);
  const handleChange = (_: React.ChangeEvent<unknown>, value: number) => {
    window.scrollTo(0, 0);
    setPage(value);
  };

  // Reset to the first page when search result changed
  React.useEffect(() => {
    window.scrollTo(0, 0);
    setPage(1);
  }, [NUM_PAGE]);

  const searchResultText =
    dashboards.length <= 1
      ? `${dashboards.length} ${searchResultContent?.SingularEntryLabel}`
      : `${dashboards.length} ${searchResultContent?.PluralEntryLabel}`;

  if (dashboardsPageContent) {
    const cardContent = { publishedLabel: searchResultContent?.CreatedDateLabel || '', authorLabel: dashboardsPageContent.authorLabel };
    return (
      <>
        <Seo title={dashboardsPageContent.title} isTemplatePage={false} />
        <Container maxWidth='xl' className={classes.root}>
          <Grid item xs={12} sm={9}>
            <div>
              <Typography className={classes.pageTitle} variant='h1'>
                {dashboardsPageContent.title}
              </Typography>
              <Typography variant='body1'>{searchResultText}</Typography>
            </div>
            <Grid container direction='row' className={classes.infoBar}>
              <Typography className={classes.infoText} variant='body2'>
                <InfoOutlined className={classes.infoIcon} /> {dashboardsPageContent.info}
              </Typography>
            </Grid>

            {dashboards.map((dashboard) => (
              <DashboardCard dashboard={dashboard} cardContent={cardContent} key={dashboard.slug} />
            ))}

            <div className={classes.paginationContainer}>
              <Pagination count={NUM_PAGE} page={page} onChange={handleChange} color='primary' />
            </div>
          </Grid>
        </Container>
      </>
    );
  }

  return null;
};

export default DashboardsLayout;
