import { useStaticQuery, graphql } from 'gatsby';
import { DashboardsPageQueryProps } from 'typings';

const dashboardsPageQuery = graphql`
  query DashboardsLayoutQuery {
    allStrapiDashboardListPage {
      nodes {
        locale
        title
        info
        authorLabel
      }
    }
    allStrapiDashboard {
      nodes {
        id
        slug
        title
        author
        description
        published_at
      }
    }
    allStrapiSearchResult {
      nodes {
        PluralEntryLabel
        SingularEntryLabel
        CreatedDateLabel
        locale
      }
    }
  }
`;

export const useStrapiDashboards = () => {
  const { allStrapiDashboardListPage, allStrapiDashboard, allStrapiSearchResult } =
    useStaticQuery<DashboardsPageQueryProps>(dashboardsPageQuery);
  const strapiDashboardPage = allStrapiDashboardListPage ? allStrapiDashboardListPage.nodes : [];
  const dashboards = allStrapiDashboard ? allStrapiDashboard.nodes : [];
  const strapiSearchResult = allStrapiSearchResult ? allStrapiSearchResult.nodes : [];
  return { strapiDashboardPage, dashboards, strapiSearchResult };
};
