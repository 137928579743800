import { makeStyles, createStyles } from '@material-ui/core/styles';

const useDashboardsLayoutStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '1.5rem'
    },
    pageTitle: {
      fontSize: '1.45rem',
      fontWeight: 400
    },
    paginationContainer: {
      display: 'grid',
      placeItems: 'center'
    },
    infoText: {
      padding: '0.2rem',
      display: 'flex',
      alignItems: 'center'
    },
    infoIcon: {
      color: 'rgba(40, 100, 172, 1)',
      marginRight: '0.5rem'
    },
    infoBar: {
      width: '100%',
      margin: '1rem 0',
      padding: '10px 14px',
      backgroundColor: 'rgba(213, 224, 238, 1)',
      borderStyle: 'solid',
      borderWidth: '2px',
      borderRadius: '2rem',
      borderColor: 'rgba(213, 224, 238, 1)',
      justifyContent: 'flex-start',
      alignItems: 'center'
    }
  })
);

export default useDashboardsLayoutStyles;
