import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { StaticImage } from 'gatsby-plugin-image';
import { StrapiDashboard, CardContent, DashboardCardProps } from 'typings';
import { navigate } from 'gatsby';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { matchesMobile } from '../../utils/mediaQuery';
import useDashboardCardStyles from '../../styles/DashboardCard';

const publishedDateElement = (classes: ClassNameMap, cardContent: CardContent, publishDate: string) => (
  <Typography variant='subtitle1' className={classes.publishDate}>
    <small>
      {cardContent.publishedLabel} {new Date(publishDate).toLocaleDateString()}
    </small>
  </Typography>
);

const DashboardCard: React.FC<DashboardCardProps> = ({ dashboard, cardContent }) => {
  const classes = useDashboardCardStyles();
  const { title, description, published_at, slug, author } = dashboard; // eslint-disable-line @typescript-eslint/naming-convention

  const handleDashboardOpen = (dashboardSlug: StrapiDashboard['slug']) => {
    navigate(`/dashboard/${dashboardSlug}`);
  };

  const isMobile = matchesMobile();
  const publishedDate = publishedDateElement(classes, cardContent, published_at);

  return (
    <Paper className={classes.root} onClick={() => handleDashboardOpen(slug)}>
      <Grid container>
        <Grid item xs={3} md={2}>
          <Grid container alignItems='center' justify='center' className={classes.padding}>
            <StaticImage
              className={`${isMobile ? classes.imageMobile : classes.image}`}
              layout='fullWidth'
              src='../../assets/desktop_graphic_thumbnail.png'
              alt={title}
            />
          </Grid>
        </Grid>
        <Grid item xs={9} md={10} className={`${classes.padding} ${isMobile ? classes.itemSectionMobile : classes.itemSection}`}>
          <Grid container direction='row'>
            <Grid item xs={12} md={9}>
              <Typography gutterBottom variant='h2' className={classes.title}>
                {title}
              </Typography>
            </Grid>
            {!isMobile ? (
              <Grid item md={3}>
                {publishedDate}
              </Grid>
            ) : null}
          </Grid>
          <Grid container direction='row'>
            <Grid item xs={11}>
              <Typography gutterBottom noWrap variant='body2'>
                {description}
              </Typography>
              <Typography gutterBottom variant='body2' color='primary'>
                {cardContent.authorLabel} {author}
              </Typography>
              {isMobile ? publishedDate : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DashboardCard;
