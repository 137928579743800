import { makeStyles, createStyles } from '@material-ui/core/styles';

const useDashboardCardStyles = makeStyles(() =>
  createStyles({
    root: {
      margin: '1rem 0'
    },
    image: {
      width: 100,
      height: 100
    },
    imageMobile: {
      width: 50,
      height: 50
    },
    title: {
      fontSize: '1rem',
      fontWeight: 500
    },
    publishDate: {
      fontSize: '0.875rem',
      fontWeight: 400,
      color: 'rgba(0, 0, 0, 0.6)',
      textAlign: 'right'
    },
    padding: {
      padding: '0.75rem 0'
    },
    itemSection: {
      marginLeft: '-1rem'
    },
    itemSectionMobile: {
      marginLeft: '0'
    }
  })
);

export default useDashboardCardStyles;
